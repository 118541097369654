import {
    makeSelectDateByContext,
    makeAdditionalFilterSelectorsByContext, makeSelectProfileSelectorSelectionByContext
} from 'src/selectors/filterSelectors';
import * as customPropTypes from 'src/customPropTypes';
import BatchMetricRequestContext from 'src/components/context/BatchMetricRequestContext';
import { connect } from 'react-redux';
import { makeCreateBatchServerRequestForDashboardMetrics } from 'src/requestHandling/metricDataRequests';
import { makeSelectDashboardMetricsByIds } from 'src/selectors/dashboardMetrics';
import PropTypes from 'prop-types';
import React from 'react';
import memoizeOne from 'memoize-one';
import withFilterContextConsumer from 'src/components/withFilterContextConsumer';

const memoizedCreateBatchServerRequestForDashboardMetrics = memoizeOne(makeCreateBatchServerRequestForDashboardMetrics());

const defaultPaginationAndSorting = {};

const BatchDashboardMetricRequestParamsProvider = (props) => {
    const {
        children, dashboardMetrics, selectedProfilesOrGroups, selectedDate, format, batchIdentifier, batchName, selectedPostText, selectedPostTextExclude, selectedPostTag, isSharedDashboardContext, selectedAdCampaign
    } = props;
    const singleRequestIdentifierPrefix = 'dashboard-metric';
    const batchServerRequest = memoizedCreateBatchServerRequestForDashboardMetrics(
        batchIdentifier,
        batchName,
        singleRequestIdentifierPrefix,
        selectedProfilesOrGroups,
        selectedDate,
        selectedPostText,
        selectedPostTextExclude,
        selectedPostTag,
        selectedAdCampaign,
        dashboardMetrics,
        format,
        defaultPaginationAndSorting,
        isSharedDashboardContext
    );

    return (
        <BatchMetricRequestContext.Provider value={batchServerRequest}>
            { children(batchServerRequest) }
        </BatchMetricRequestContext.Provider>
    );
};

BatchDashboardMetricRequestParamsProvider.propTypes = {
    children: PropTypes.func.isRequired,
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    selectedDate: customPropTypes.selectedDate.isRequired,
    selectedPostText: customPropTypes.postTextFilter.isRequired,
    selectedPostTextExclude: customPropTypes.postTextExcludeFilter.isRequired,
    selectedAdCampaign: customPropTypes.adCampaignFilter.isRequired,
    format: customPropTypes.format.isRequired,
    dashboardMetrics: customPropTypes.dashboardMetrics.isRequired,
    dashboard: customPropTypes.dashboard.isRequired,
    batchIdentifier: PropTypes.string.isRequired,
    batchName: PropTypes.string.isRequired,
    selectedPostTag: customPropTypes.postTagFilter.isRequired,
    isSharedDashboardContext: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectDashboardMetricsByIds = makeSelectDashboardMetricsByIds();
    const selectDateByContext = makeSelectDateByContext();
    const selectProfileSelectorSelectionByContext = makeSelectProfileSelectorSelectionByContext();
    const {
        selectPostTagByContext, selectPostTextByContext, selectPostTextExcludeByContext, selectAdCampaignByContext
    } = makeAdditionalFilterSelectorsByContext();

    return (state, ownProps) => {
        const { dashboard, filterContext } = ownProps;
        return {
            dashboardMetrics: selectDashboardMetricsByIds(state, dashboard.dashboardMetricIds),
            selectedDate: selectDateByContext(state, filterContext),
            selectedProfilesOrGroups: selectProfileSelectorSelectionByContext(state, filterContext),
            selectedPostText: selectPostTextByContext(state, filterContext),
            selectedPostTextExclude: selectPostTextExcludeByContext(state, filterContext),
            selectedPostTag: selectPostTagByContext(state, filterContext),
            selectedAdCampaign: selectAdCampaignByContext(state, filterContext)
        };
    };
};

export default withFilterContextConsumer(connect(makeMapStateToProps)(BatchDashboardMetricRequestParamsProvider));
