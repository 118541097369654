import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/headers/header.scss';

const Column = ({
    flexGrow, flexShrink, growNoShrink, className, style, children
}) => (
    <div
      className={classnames(styles.column, {
          [styles.flexGrow]: flexGrow,
          [styles.flexShrink]: flexShrink,
          [styles.growNoShrink]: growNoShrink
      }, className)}
      style={style}
    >
        {children}
    </div>
);

Column.propTypes = {
    flexGrow: PropTypes.bool,
    flexShrink: PropTypes.bool,
    growNoShrink: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    children: customPropTypes.children.isRequired
};

Column.defaultProps = {
    flexGrow: false,
    flexShrink: false,
    growNoShrink: false,
    className: '',
    style: {}
};

export default Column;
