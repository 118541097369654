import * as customPropTypes from 'src/customPropTypes';
import ChartViewWithAsyncStateHandlingLazy from 'src/components/chartViews/ChartViewWithAsyncStateHandling';
import classnames from 'classnames';
import ErrorBody from 'src/components/metricTiles/layout/ErrorBody';
import ErrorBoundarySuspense from 'src/components/errors/ErrorBoundarySuspense';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/dataBody.scss';
import TableCellSettingContextProvider from 'src/components/metricTiles/TableCellSettingContextProvider';
import MetricTileBody from './MetricTileBody';

const DataBody = (props) => {
    const {
        selectedDate,
        selectedProfilesOrGroups,
        accountOptions,
        metric,
        settings,
        data,
        loading,
        error,
        lazyLoadChart,
        isSampleMetric,
        showRawErrors,
        showLinkInTable
    } = props;
    return (
        <MetricTileBody>
            <ErrorBoundarySuspense renderErrorBody={(errorFromBoundary) => <ErrorBody error={errorFromBoundary} />}>
                <TableCellSettingContextProvider showLink={showLinkInTable}>
                    <ChartViewWithAsyncStateHandlingLazy
                      className={classnames(styles.chartView)}
                      selectedDate={selectedDate}
                      selectedProfilesOrGroups={selectedProfilesOrGroups}
                      accountOptions={accountOptions}
                      metric={metric}
                      settings={settings}
                      data={data}
                      loading={loading}
                      error={error}
                      lazyLoad={lazyLoadChart}
                      showRawErrors={showRawErrors}
                      isSampleMetric={isSampleMetric}
                    />
                </TableCellSettingContextProvider>
            </ErrorBoundarySuspense>
        </MetricTileBody>
    );
};

DataBody.propTypes = {
    selectedDate: customPropTypes.selectedDate.isRequired,
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired,
    metric: customPropTypes.minimalMetricShapeForDataLoading.isRequired,
    settings: customPropTypes.dashboardMetricSettings.isRequired,
    data: customPropTypes.metricServerResponse,
    error: customPropTypes.dataLoadingError,
    loading: PropTypes.bool.isRequired,
    lazyLoadChart: PropTypes.bool,
    isSampleMetric: PropTypes.bool,
    showRawErrors: PropTypes.bool,
    showLinkInTable: customPropTypes.showLinkInTable
};

DataBody.defaultProps = {
    lazyLoadChart: false,
    isSampleMetric: false,
    showRawErrors: true,
    showLinkInTable: 'in-app'
};

export default DataBody;
