import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { createShallowEqualSelector } from 'src/selectors/utils';

const getServerDataFromStore = (state) => state.serverData.data;
const getBatchLoadingStatesFromStore = (state) => state.serverData.batchLoadingStates;

const cacheDurationInMilliseconds = 5 * 60 * 1000;

const cacheStates = {
    cacheDataValid: 'cacheDataValid',
    cacheDataInvalid: 'cacheDataInvalid'
};

export const makeSelectServerDataForJobHash = (timestampToCheck) => createSelector(
    [
        createSelector(
            [
                getServerDataFromStore,
                (_, hash) => hash
            ],
            (serverData, hash) => _get(serverData, [hash, 'data'], false)
        ),
        createSelector(
            [
                getServerDataFromStore,
                (_, hash) => hash
            ],
            (timestampsForData, hash) => {
                const timestampForHash = _get(timestampsForData, [hash, 'timestamp']);
                if (timestampForHash) {
                    const timeDiff = timestampToCheck - timestampForHash;
                    if (timeDiff >= cacheDurationInMilliseconds) {
                        return cacheStates.cacheDataInvalid;
                    }
                }
                return cacheStates.cacheDataValid;
            }
        )
    ],
    (serverDataForJobHash, currentCacheState) => {
        if (serverDataForJobHash === false || currentCacheState === cacheStates.cacheDataInvalid) {
            return {
                loading: true,
                data: null,
                error: null,
                requested: false
            };
        }
        return serverDataForJobHash;
    }
);

export const selectIsBatchLoading = (state, batchIdentifier) => {
    const batchLoadingStates = getBatchLoadingStatesFromStore(state);
    const loadingStateCount = _get(batchLoadingStates, batchIdentifier, 0);
    return loadingStateCount > 0;
};

export const makeSelectServerDataByServerDataParams = (timestampToCheck) => {
    const selectorCache = {};
    const getSelector = (hash) => {
        const selector = _get(selectorCache, hash);
        if (selector) { return selector; }
        Object.assign(selectorCache, { [hash]: makeSelectServerDataForJobHash(timestampToCheck) });
        return selectorCache[hash];
    };
    return createShallowEqualSelector(
        [
            (state, batchServerRequest) => {
                const entities = {};
                batchServerRequest.requests.forEach((requests) => {
                    const { hash } = requests;
                    Object.assign(entities, { [hash]: getSelector(hash)(state, hash) });
                });
                return entities;
            },
        ],
        (serverDataSlice) => serverDataSlice
    );
};

export const selectTimestampByHash = createSelector(
    [
        getServerDataFromStore,
        (_, hash) => hash
    ],
    (timestamps, hash) => _get(timestamps, [hash, 'timestamp'])
);
