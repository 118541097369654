import React, { Component } from 'react';
import * as lastScrolledPositionActionCreators from 'src/actions/lastScrolledPosition';
import { connect } from 'react-redux';
import { makeSelectLastScrolledPositionByIdentifier } from 'src/selectors/lastScrolledPosition';
import PropTypes from 'prop-types';

const withScrollPosition = (WrappedComponent, scrollPositionName) => {
    class WithScrollPosition extends Component {
        constructor(props) {
            super(props);
            this.handleRef = this.handleRef.bind(this);
        }

        componentDidMount() {
            const { lastScrolledPosition, resetAllScrolledPositionsAction, resetAfterRead } = this.props;
            this.bodyNode.scrollTop = lastScrolledPosition;
            if (resetAfterRead) {
                resetAllScrolledPositionsAction(scrollPositionName);
            }
        }

        componentDidUpdate(prevProps) {
            const { scrollPositionIdentifier } = this.props;
            if (scrollPositionIdentifier !== prevProps.scrollPositionIdentifier) {
                const { lastScrolledPosition, resetAllScrolledPositionsAction, resetAfterRead } = this.props;
                this.bodyNode.scrollTop = lastScrolledPosition;
                if (resetAfterRead) {
                    resetAllScrolledPositionsAction(scrollPositionName);
                }
            } else if (scrollPositionIdentifier === prevProps.scrollPositionIdentifier) {
                const { locationKey } = this.props;
                if (locationKey && locationKey !== prevProps.locationKey) {
                    this.bodyNode.scrollTop = 0;
                }
            }
        }

        componentWillUnmount() {
            const bodyScrollTop = Math.round(this.bodyNode.scrollTop);
            const { updateLastScrolledPositionAction, scrollPositionIdentifier, lastScrolledPosition } = this.props;
            if (bodyScrollTop !== lastScrolledPosition) {
                updateLastScrolledPositionAction(scrollPositionName, scrollPositionIdentifier, bodyScrollTop);
            }
        }

        handleRef(bodyNode) {
            this.bodyNode = bodyNode;
        }

        render() {
            return (
                <WrappedComponent {...this.props} bodyRefForScrollPosition={this.handleRef} />
            );
        }
    }

    WithScrollPosition.propTypes = {
        updateLastScrolledPositionAction: PropTypes.func.isRequired,
        resetAllScrolledPositionsAction: PropTypes.func.isRequired,
        scrollPositionIdentifier: PropTypes.string.isRequired,
        lastScrolledPosition: PropTypes.number.isRequired,
        resetAfterRead: PropTypes.bool,
        locationKey: PropTypes.string
    };

    WithScrollPosition.defaultProps = {
        // If you also hand over a locationKey, it will scroll to top whenever the scrollIdentifier does not change
        // but the location key does.
        locationKey: '',
        // reset after read clears all other scroll positions within that context because otherwise it would also
        // store positions of "forward" browsing. Means: You read your scroll position and clear ALL other within this context.
        // Once you leave the location it will unmount und write the last scroll position again
        resetAfterRead: false
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithScrollPosition.displayName = `withScrollPosition(${wrappedComponentName})`;

    const makeMapStateToProps = () => {
        const selectLastScrolledPositionByIdentifier = makeSelectLastScrolledPositionByIdentifier(scrollPositionName);
        return (state, ownProps) => ({
            lastScrolledPosition: selectLastScrolledPositionByIdentifier(state, ownProps.scrollPositionIdentifier)
        });
    };

    return connect(makeMapStateToProps, {
        updateLastScrolledPositionAction: lastScrolledPositionActionCreators.updateLastScrolledPosition,
        resetAllScrolledPositionsAction: lastScrolledPositionActionCreators.resetAllScrolledPositions
    })(WithScrollPosition);
};

export default withScrollPosition;
