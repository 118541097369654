import { LAST_SCROLLED_POSITION, RESET_ALL_SCROLLED_POSTIONS } from 'src/actions/lastScrolledPosition';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { setIn } from './utils';

export function lastScrolledPosition(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case LAST_SCROLLED_POSITION: {
            const { identifier, position, scrollPositionName } = payload;
            const path = `${scrollPositionName}.${identifier}`;
            if (_has(state, path) && _get(state, path) === position) {
                return state;
            }
            return setIn(state, path, position);
        }
        case RESET_ALL_SCROLLED_POSTIONS: {
            const { scrollPositionName } = payload;
            return setIn(state, scrollPositionName, {});
        }
        default:
            return state;
    }
}
