import DeprecatedDataSource from 'src/components/feedback/warnings/DeprecatedDataSource';
import FacebookInsightsMissingData from 'src/components/feedback/warnings/FacebookInsightsMissingData';
import FacebookInsightsFacebookBug from 'src/components/feedback/warnings/FacebookInsightsFacebookBug';
import InstagramBusinessAccountConversionMissingPosts from 'src/components/feedback/warnings/InstagramBusinessAccountConversionMissingPosts';
import NotFullyCovered from 'src/components/feedback/warnings/NotFullyCovered';
import UseCaseViolation from 'src/components/feedback/warnings/UseCaseViolation';
import YoutubeTimeBasedLimitationWarning from 'src/components/feedback/warnings/YoutubeTimeBasedLimitationWarning';
import Popover from 'src/components/popovers/layout/Popover';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/popovers/dataWarningPopover.scss';

const DataWarningPopover = ({ warnings }) => {
    const {
        notFullyCovered,
        deprecatedDataSource,
        instagramBusinessAccountConversionMissingPosts,
        youtubeTimeBasedLimitationWarning,
        facebookInsightsMissingData,
        facebookInsightsFacebookBug,
        useCaseViolation
    } = warnings;

    const content = [];

    // this order determines the order of how the warnings appear
    if (youtubeTimeBasedLimitationWarning) {
        content.push(<YoutubeTimeBasedLimitationWarning
          warningData={youtubeTimeBasedLimitationWarning}
          key="youtubeTimeBasedLimitationWarning"
        />);
    }
    if (deprecatedDataSource) {
        content.push(<DeprecatedDataSource warningData={deprecatedDataSource} key="deprecatedDataSourceWarning" />);
    }
    if (instagramBusinessAccountConversionMissingPosts) {
        content.push(<InstagramBusinessAccountConversionMissingPosts
          warningData={instagramBusinessAccountConversionMissingPosts}
          key="instagramBusinessAccountConversionMissingPostsWarning"
        />);
    }
    if (facebookInsightsMissingData) {
        content.push(<FacebookInsightsMissingData warningData={facebookInsightsMissingData} key="facebookInsightsMissingDataWarning" />);
    }
    if (facebookInsightsFacebookBug) {
        content.push(<FacebookInsightsFacebookBug warningData={facebookInsightsFacebookBug} key="facebookInsightsFacebookBugWarning" />);
    }

    if (useCaseViolation) {
        content.push(
            <UseCaseViolation
              key="useCaseViolation"
              warningData={useCaseViolation}
            />
        );
    }

    if (notFullyCovered) {
        content.push(<NotFullyCovered warningData={notFullyCovered} key="notFullyCoveredWarning" />);
    }

    return (
        <Popover reminder id="data-warning-popover">
            <div className={styles.popover}>{content}</div>
        </Popover>
    );
};

DataWarningPopover.propTypes = {
    warnings: customPropTypes.metricServerResponseWarnings.isRequired
};

export default DataWarningPopover;
