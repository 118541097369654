import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import HeaderWithHeadline from 'src/components/header/HeaderWithHeadline';
import Body from 'src/components/Body';
import PropTypes from 'prop-types';
import Error from 'src/components/errors/Error';
import Button from 'src/components/buttons/Button';
import { showReportDialog } from 'src/utils/sentry';
import styles from 'src/stylesheets/errorView.scss';

const ErrorView = ({
    headline, error, type, sentryEventId
}) => (
    <ScrollView dataViewName="error-screen">
        <HeaderWithHeadline headline={headline} />
        <Body>
            <Error error={error} type={type} />
            {
                sentryEventId && (
                    <div className={styles.feedback}>
                        <Button label="Report feedback" onClick={() => { showReportDialog(sentryEventId); }} />
                    </div>
                )
            }
        </Body>
    </ScrollView>
);

ErrorView.propTypes = {
    error: customPropTypes.dataLoadingError.isRequired,
    headline: PropTypes.string.isRequired,
    type: PropTypes.string,
    sentryEventId: PropTypes.string
};

export default ErrorView;
