import {
    SEARCH_POSTS_REQUEST,
    SEARCH_POSTS_SUCCESS,
    PAGINATE_POSTS_REQUEST,
    PAGINATE_POSTS_ERROR,
    PAGINATE_POSTS_SUCCESS,
    SEARCH_POSTS_ERROR,
    TAG_ALL_POSTS,
    GET_POST_SUCCESS,
    GET_POST_REQUEST,
    GET_POST_ERROR,
    SHARED_DASHBOARD_POST_GET_REQUEST,
    SHARED_DASHBOARD_POST_GET_ERROR,
    SHARED_DASHBOARD_POST_GET_SUCCESS,
    INVALIDATE_POST_SEARCH_HASH,
    ADD_REMOVE_POST_TAGS_FROM_POST_ERROR,
    ADD_REMOVE_POST_TAGS_FROM_POST_REQUEST,
    ADD_REMOVE_POST_TAGS_FROM_POST_SUCCESS,
    ADD_REMOVE_POST_TAGS_FROM_POSTS_ERROR,
    ADD_REMOVE_POST_TAGS_FROM_POSTS_REQUEST,
    ADD_REMOVE_POST_TAGS_FROM_POSTS_SUCCESS,
    CLEANUP_ADD_REMOVE_POST_TAGS_FROM_POST_STATE
} from 'src/actions/posts';
import { combineReducers } from 'redux';
import {
    CHECK_ALL_LIST_VALUES,
    CHECK_LIST_VALUES,
    TOGGLE_LIST_VALUE,
    UNCHECK_ALL_LIST_VALUES,
    UNCHECK_LIST_VALUE,
    UNREGISTER_LIST,
    UNREGISTER_LIST_ITEMS,
    UNCHECK_LIST_VALUES,
} from 'src/actions/lists';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';
import {
    POST_SEARCH_BY_TAG_ERROR,
    POST_SEARCH_BY_TAG_REQUEST,
    POST_SEARCH_BY_TAG_SUCCESS
} from 'src/actions/postTags';

const inputDefaultState = {
    filter: {
        profile: { },
        date: {
            from: '',
            to: '',
            interval: 'daily'
        },
    },
    parameters: {
        sortDir: 'desc',
        start: 0,
        limit: 50
    },
    hash: ''
};

const input = (state = inputDefaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SEARCH_POSTS_REQUEST:
        case PAGINATE_POSTS_REQUEST: {
            const { searchRequestInput } = payload;
            return Object.assign({}, state, searchRequestInput);
        }
        case INVALIDATE_POST_SEARCH_HASH: {
            return Object.assign({}, state, { hash: '' });
        }
        default:
            return state;
    }
};

const shouldTagAllPosts = (state = false, actions) => {
    const { type } = actions;
    switch (type) {
        case TAG_ALL_POSTS: {
            return true;
        }
        case CHECK_ALL_LIST_VALUES:
        case UNCHECK_ALL_LIST_VALUES:
        case UNCHECK_LIST_VALUE:
        case CHECK_LIST_VALUES:
        case TOGGLE_LIST_VALUE:
        case UNREGISTER_LIST:
        case UNCHECK_LIST_VALUES:
        case UNREGISTER_LIST_ITEMS: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const byId = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case SEARCH_POSTS_SUCCESS: {
            const { posts } = payload;
            return Object.assign({}, posts);
        }
        case SEARCH_POSTS_ERROR: {
            return {};
        }
        case PAGINATE_POSTS_SUCCESS: {
            const { posts } = payload;
            return Object.assign({}, posts);
        }
        case GET_POST_SUCCESS:
        case SHARED_DASHBOARD_POST_GET_SUCCESS: {
            const { post } = payload;
            return Object.assign({}, state, { [post.id]: post });
        }
        case POST_SEARCH_BY_TAG_SUCCESS: {
            const { posts } = payload;
            return Object.assign({}, state, posts);
        }
        default:
            return state;
    }
};

const idsByPage = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case SEARCH_POSTS_SUCCESS: {
            const { pageNumber, posts } = payload;
            return Object.assign({}, { [pageNumber]: Object.keys(posts) });
        }
        case PAGINATE_POSTS_SUCCESS: {
            const { pageNumber, posts } = payload;
            return Object.assign({}, state, { [pageNumber]: Object.keys(posts) });
        }
        case SEARCH_POSTS_ERROR: {
            return {};
        }
        default:
            return state;
    }
};

const pageNumber = (state = 1, action) => {
    const { type, payload } = action;
    switch (type) {
        case SEARCH_POSTS_SUCCESS:
        case PAGINATE_POSTS_SUCCESS: {
            const { pageNumber: pageNumberFromPayload } = payload;
            return pageNumberFromPayload;
        }
        case SEARCH_POSTS_ERROR: {
            return 1;
        }
        default:
            return state;
    }
};

const totalRowCount = (state = 0, action) => {
    const { type, payload } = action;
    switch (type) {
        case SEARCH_POSTS_SUCCESS: {
            const { totalRowCount: totalFromPayload } = payload;
            return totalFromPayload;
        }
        case SEARCH_POSTS_ERROR: {
            return 0;
        }
        default:
            return state;
    }
};

const warnings = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case SEARCH_POSTS_SUCCESS: {
            return payload.warnings;
        }
        case SEARCH_POSTS_ERROR: {
            return {};
        }
        default:
            return state;
    }
};

const result = combineReducers({
    idsByPage,
    pageNumber,
    totalRowCount,
    warnings
});

const asyncStates = combineReducers({
    getPostInfo: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.postId,
        [GET_POST_REQUEST, SHARED_DASHBOARD_POST_GET_REQUEST],
        [GET_POST_SUCCESS, SHARED_DASHBOARD_POST_GET_SUCCESS],
        [GET_POST_ERROR, SHARED_DASHBOARD_POST_GET_ERROR]
    ),
    request: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'state',
        [SEARCH_POSTS_REQUEST, PAGINATE_POSTS_REQUEST],
        [SEARCH_POSTS_SUCCESS, PAGINATE_POSTS_SUCCESS],
        [SEARCH_POSTS_ERROR, PAGINATE_POSTS_ERROR]
    ),
    search: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.postTagId,
        [POST_SEARCH_BY_TAG_REQUEST],
        [POST_SEARCH_BY_TAG_SUCCESS],
        [POST_SEARCH_BY_TAG_ERROR]
    ),
    addRemovePostTags: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'state',
        [ADD_REMOVE_POST_TAGS_FROM_POST_REQUEST, ADD_REMOVE_POST_TAGS_FROM_POSTS_REQUEST],
        [ADD_REMOVE_POST_TAGS_FROM_POST_SUCCESS, ADD_REMOVE_POST_TAGS_FROM_POSTS_SUCCESS],
        [ADD_REMOVE_POST_TAGS_FROM_POST_ERROR, ADD_REMOVE_POST_TAGS_FROM_POSTS_ERROR],
        [CLEANUP_ADD_REMOVE_POST_TAGS_FROM_POST_STATE]
    ),
});

const postsSearchByFilter = combineReducers({
    input,
    result,
    shouldTagAllPosts
});

const idsByPostTag = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case POST_SEARCH_BY_TAG_SUCCESS: {
            const { postTagId, posts } = payload;
            return Object.assign({}, state, { [postTagId]: Object.keys(posts) });
        }
        default:
            return state;
    }
};

export const posts = combineReducers({
    byId,
    idsByPostTag,
    postsSearchByFilter,
    asyncStates
});
