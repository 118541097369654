import Button from 'src/components/buttons/Button';
import DataWarningPopover from 'src/components/popovers/DataWarningPopover';
import React from 'react';
import PropTypes from 'prop-types';
import withTooltip from 'src/components/withToolTip';
import withPopover from 'src/components/withPopover';
import * as customPropTypes from 'src/customPropTypes';
import _isEmpty from 'lodash/isEmpty';

const ButtonWithTooltipWithPopover = withPopover(withTooltip(Button));

const getWarningText = (numberOfwarnings) => {
    let numberInWords = '';
    switch (numberOfwarnings) {
        case 1: numberInWords = 'one'; break;
        case 2: numberInWords = 'two'; break;
        case 3: numberInWords = 'three'; break;
        default: numberInWords = 'many'; break;
    }
    const singular = numberOfwarnings === 1;
    return `There ${singular ? 'is' : 'are'} ${numberInWords} warning${singular ? '' : 's'} for this metric`;
};

const DataWarningButton = (props) => {
    const {
        small, className, warnings, disabled
    } = props;
    if (warnings && !_isEmpty(warnings)) {
        const numberOfWarnings = warnings ? Object.keys(warnings).length : 0;
        const overlay = <DataWarningPopover warnings={warnings} />;
        return <ButtonWithTooltipWithPopover className={className} overlay={overlay} tooltip={getWarningText(numberOfWarnings)} small={small} icon="alert" reminder disabled={disabled} />;
    }
    return null;
};

DataWarningButton.propTypes = {
    small: PropTypes.bool,
    className: PropTypes.string,
    warnings: PropTypes.oneOfType([customPropTypes.metricServerResponseWarnings, customPropTypes.postSearchResponseWarnings]),
    disabled: PropTypes.bool
};

DataWarningButton.defaultProps = {
    small: false,
    disabled: false
};

export default DataWarningButton;
