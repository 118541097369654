export const LAST_SCROLLED_POSITION = 'LAST_SCROLLED_POSITION';
export const RESET_ALL_SCROLLED_POSTIONS = 'RESET_ALL_SCROLLED_POSTIONS';

export const updateLastScrolledPosition = (scrollPositionName, identifier, position) => ({
    type: LAST_SCROLLED_POSITION,
    payload: { scrollPositionName, identifier, position }
});

export const resetAllScrolledPositions = (scrollPositionName) => ({
    type: RESET_ALL_SCROLLED_POSTIONS,
    payload: { scrollPositionName }
});
