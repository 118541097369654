import { combineReducers } from 'redux';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';

const dataTypesById = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN: {
            return payload.dataTypes;
        }
        default:
            return state;
    }
};

const dataTypeIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN: {
            return [...Object.keys(payload.dataTypes)];
        }
        default:
            return state;
    }
};

export const dataTypes = combineReducers({ byId: dataTypesById, allIds: dataTypeIds });
