import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/headers/header.scss';

const BottomRow = ({ className, children }) => (
    <div className={classnames(styles.row, styles.bottom, className)}>{children}</div>
);

BottomRow.propTypes = {
    className: PropTypes.string,
    children: customPropTypes.children.isRequired,
};

BottomRow.defaultProps = {
    className: ''
};

export default BottomRow;
