import { parseRequestInfo } from 'src/parsers';
import {
    all, call, put, takeEvery
} from 'redux-saga/effects';
import {
    getWidgetsGroupedByDataSourceUsage, getWidgetsSplitIntoRequests, handleAuthorizedServerRequest, getMetricRequest
} from 'src/sagas/utils';
import {
    SERVER_DATA_REQUEST, serverDataBucketError, serverDataBucketSuccess, severDataSuccess
} from 'src/actions/serverData';
import _now from 'lodash/now';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { reportError } from 'src/utils/reportError';
import { showLoading } from 'react-redux-loading-bar';
import _get from 'lodash/get';
import _has from 'lodash/has';

const parseResults = (response) => {
    const parsedResponse = Object.assign({}, response);
    if (_has(response, 'requestInfo')) {
        Object.assign(parsedResponse, { requestInfo: parseRequestInfo(response.requestInfo) });
    }
    return parsedResponse;
};

const getErrorFromResponse = (response) => {
    const errorResponse = {
        errorType: _get(response, 'errors.errorType', null),
        message: _get(response, 'errors.reason', 'An unexpected error occured.')
    };

    if (_has(response, 'requestInfo')) {
        Object.assign(errorResponse, { requestInfo: parseRequestInfo(response.requestInfo) });
    }
    return errorResponse;
};

const extractResponse = (results, serverRequest, format) => {
    const requestIdentifier = serverRequest.identifier;
    const metricData = results[requestIdentifier];
    switch (format) {
        case 'json': {
            const response = {
                data: null,
                error: null,
            };
            if (_get(metricData, 'success') === true) {
                response.data = parseResults(metricData);
            } else {
                response.error = getErrorFromResponse(metricData);
            }
            return response;
        }
        case 'console': {
            const { queryResult, visualization } = results;
            const queryResultSlice = queryResult[requestIdentifier];
            const visualizationSlice = visualization[requestIdentifier];

            const response = {
                data: null,
                error: null,
            };

            // parse results accordingly to jobs
            if (queryResultSlice && visualizationSlice) {
                response.data = { queryResult: queryResultSlice, visualization: parseResults(visualizationSlice) };
            } else {
                response.error = getErrorFromResponse(results);
            }
            return response;
        }
        default:
            throw new Error('Only json and console formats are supported in tool');
    }
};

const bucketToRequest = (bucket) => bucket.map((request) => getMetricRequest(request));

function* serverDataBucketRequestHandling(bucket, endpointInfo, requestVariables) {
    try {
        const requestParams = bucketToRequest(bucket);
        const { format, credentials } = endpointInfo;
        const params = {
            format,
            metricRequests: JSON.stringify(requestParams),
            requestVariables: JSON.stringify(requestVariables)
        };

        if (credentials) {
            Object.assign(params, credentials);
        }

        yield put(showLoading());
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield call(handleAuthorizedServerRequest, serverRequest, endpointInfo.to);
        const now = _now();
        if (response) {
            const { jsonData } = response;
            const collectedResponses = bucket.map((job) => ({
                hash: job.hash,
                data: Object.assign(extractResponse(jsonData, job, format), { loading: false }),
                timestamp: now
            }));
            yield put(serverDataBucketSuccess(collectedResponses));
        }
        if (serverError) {
            const collectedErrors = bucket.map((job) => ({
                hash: job.hash,
                data: {
                    data: null,
                    loading: false,
                    error: {
                        errorType: serverError.errorType || 'dataLoadingError',
                        message: serverError.message,
                        requestInfo: serverError.requestInfo ? parseRequestInfo(serverError.requestInfo) : undefined
                    }
                },
                timestamp: now
            }));
            yield put(serverDataBucketError(collectedErrors));
        }
    } catch (applicationError) {
        reportError(applicationError);
    }
}

function* serverDataRequest(action) {
    const { payload } = action;
    const {
        serverDataJobs, endpointInfo, batchIdentifier, requestVariables
    } = payload;
    const jobsGroupedByDataSourceUsage = getWidgetsGroupedByDataSourceUsage(serverDataJobs);

    const buckets = getWidgetsSplitIntoRequests(jobsGroupedByDataSourceUsage);

    const jobs = buckets.map((bucket) => call(serverDataBucketRequestHandling, bucket, endpointInfo, requestVariables));
    yield all(jobs);
    yield put(severDataSuccess(batchIdentifier));
}

export default function* serverDataSagas() {
    yield takeEvery(SERVER_DATA_REQUEST, serverDataRequest);
}
