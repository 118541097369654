import {
    put,
    select,
    takeEvery,
    call
} from 'redux-saga/effects';
import {
    ADD_REMOVE_POST_TAGS_FROM_POSTS_REQUEST,
    ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_REQUEST,
    ADD_REMOVE_POST_TAGS_FROM_POST_REQUEST,
    addRemovePostTagsFromPostError,
    addRemovePostTagsFromPostSuccess,
    getPostRequest as getPostRequestAction,
    addRemovePostTagsFromPostByFilterError,
    addRemovePostTagsFromPostByFilterSuccess,
    addRemovePostTagsFromPostsError,
    addRemovePostTagsFromPostsSuccess,
    SEARCH_POSTS_REQUEST,
    searchPostsError,
    searchPostsSuccess,
    CHANGE_POST_SORT_DIR,
    GET_POST_REQUEST,
    SHARED_DASHBOARD_POST_GET_REQUEST,
    PAGINATE_POSTS_REQUEST,
    paginatePostsError,
    paginatePostsSuccess,
    getPostError,
    getPostSuccess,
    searchPostsRequest as searchPostsRequestAction,
    sharedDashboardPostGetError,
    sharedDashboardPostGetSuccess,
    cleanupAddRemovePostTagsFromPostsState
} from 'src/actions/posts';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { createNewServerRequestWithNewParameters } from 'src/requestHandling/datasourceDataRequests';
import {
    handleAuthorizedServerRequest, getMetricRequest, getAddedAndRemovedIdsFromSelectedOptions
} from 'src/sagas/utils';
import {
    parsePost, parsePosts, parsePostPostTags
} from 'src/parsers';
import { SubmissionError } from 'redux-form';
import _get from 'lodash/get';
import { modalHideAddOrRemovePostTagsFromPosts, modalHideAddOrRemovePostTagsFromPost } from 'src/actions/overlays';
import { getPostsSearchInput, makeSelectPostById, makeSelectPostTagIdsByPostIdsWithIndeterminatedPostTags } from 'src/selectors/posts';
import { makeSelectQueryFromFilterSelectorByContext } from 'src/selectors/filterSelectors';
import { push } from 'react-router-redux';
import { selectIsSharedDashboardContext } from 'src/selectors/sharedDashboard';
import { getPageNumber } from 'src/utils/postSearch';
import { showNotification } from 'src/actions/notifications';
import { POST_TAGGING } from 'src/utils/filterSelectors';
import { postsRoute } from 'src/routePaths';

const selectPostById = makeSelectPostById();
const selectPostTagIdsByPostIdsWithIndeterminatedPostTags = makeSelectPostTagIdsByPostIdsWithIndeterminatedPostTags();

const getPostText = (requestInput) => _get(requestInput, 'filter.postText.values', []);

function* searchPostsRequest(action) {
    const { payload } = action;
    const { searchRequestInput } = payload;
    const { parameters } = searchRequestInput;
    const { start, limit } = parameters;

    const requestParams = {
        dataSourceDataRequest: JSON.stringify(searchRequestInput)
    };
    try {
        const serverRequest = createServerRequest(requestParams);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/search-posts-for-post-tagging');
        if (response) {
            const { posts, totalRowCount } = response.jsonData;
            const warnings = _get(response.jsonData, 'warnings', {});
            const pageNumber = getPageNumber(limit, start);
            const postTextKeywords = getPostText(searchRequestInput);
            const { posts: parsedPosts, postsPostTags } = parsePosts(posts, postTextKeywords);
            yield put(searchPostsSuccess(parsedPosts, postsPostTags, totalRowCount, pageNumber, warnings));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(searchPostsError(applicationError));
    }
}

function* paginatePostsRequest(action) {
    const { payload } = action;
    const {
        pageNumber, searchRequestInput
    } = payload;
    const serverParams = {
        dataSourceDataRequest: JSON.stringify(searchRequestInput)
    };

    try {
        const serverRequest = createServerRequest(serverParams);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/search-posts-for-post-tagging');
        if (response) {
            const { posts } = response.jsonData;
            const postTextKeywords = getPostText(searchRequestInput);
            const { posts: parsedPosts, postsPostTags } = parsePosts(posts, postTextKeywords);
            yield put(paginatePostsSuccess(parsedPosts, postsPostTags, pageNumber));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(paginatePostsError(applicationError));
    }
}

function* getAddedRemovedPostTagIds(postId, selectedTagOptions) {
    const post = yield select(selectPostById, postId);
    const { postPostTags } = post;
    const selectedPostTagIds = Object.keys(postPostTags);
    const { addedIds, removedIds } = getAddedAndRemovedIdsFromSelectedOptions(selectedPostTagIds, [], selectedTagOptions);
    return {
        addToPostTags: addedIds,
        removeFromPostTags: removedIds
    };
}

function* addRemovePostTagsFromPostRequest(action) {
    const { payload } = action;
    const { postId, selectedTagOptions, performPostSearch } = payload;
    try {
        const { addToPostTags, removeFromPostTags } = yield call(getAddedRemovedPostTagIds, postId, selectedTagOptions);

        const params = {
            postIds: JSON.stringify([postId]),
            addToPostTags: JSON.stringify(addToPostTags),
            removeFromPostTags: JSON.stringify(removeFromPostTags)
        };
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/add-remove-post-tags-from-posts');
        if (response) {
            yield put(addRemovePostTagsFromPostSuccess(addToPostTags, removeFromPostTags));
            yield put(modalHideAddOrRemovePostTagsFromPost());
            yield put(showNotification('Tags have been updated successfully.'));
            yield put(getPostRequestAction(postId));
            yield put(cleanupAddRemovePostTagsFromPostsState());
            if (performPostSearch) {
                const searchRequestInput = yield select(getPostsSearchInput);
                yield put(searchPostsRequestAction(searchRequestInput));
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(addRemovePostTagsFromPostError(applicationError));
    }
}

function* getAddedAndRemovedPostTagIds(postIds, selectedTagOptions) {
    const { selectedPostTagIds, indeterminatePostTagIds } = yield select(selectPostTagIdsByPostIdsWithIndeterminatedPostTags, postIds);
    const { addedIds, removedIds } = getAddedAndRemovedIdsFromSelectedOptions(selectedPostTagIds, indeterminatePostTagIds, selectedTagOptions);
    return {
        addToPostTags: addedIds,
        removeFromPostTags: removedIds
    };
}

function* addRemovePostTagsFromPostsRequest(action) {
    const { payload } = action;
    const { postIds, selectedTagOptions } = payload;
    try {
        const { addToPostTags, removeFromPostTags } = yield call(getAddedAndRemovedPostTagIds, postIds, selectedTagOptions);
        const params = {
            postIds: JSON.stringify(postIds),
            addToPostTags: JSON.stringify(addToPostTags),
            removeFromPostTags: JSON.stringify(removeFromPostTags)
        };
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/add-remove-post-tags-from-posts');
        if (response) {
            yield put(addRemovePostTagsFromPostsSuccess(addToPostTags, removeFromPostTags));
            yield put(modalHideAddOrRemovePostTagsFromPosts());
            yield put(showNotification('Tags have been updated successfully.'));
            const searchRequestInput = yield select(getPostsSearchInput);
            yield put(searchPostsRequestAction(searchRequestInput));
            yield put(cleanupAddRemovePostTagsFromPostsState());
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(addRemovePostTagsFromPostsError(applicationError));
    }
}

function* addRemovePostTagsFromPostsByFilterRequest(action) {
    const { payload } = action;
    const { postIds, selectedTagOptions } = payload;
    try {
        const { addToPostTags, removeFromPostTags } = yield call(getAddedAndRemovedPostTagIds, postIds, selectedTagOptions);
        const searchRequestInput = yield select(getPostsSearchInput);
        const newSearchDataSourceRequest = createNewServerRequestWithNewParameters(searchRequestInput, {
            limit: null,
            start: null
        });

        const params = {
            dataSourceDataRequest: JSON.stringify(newSearchDataSourceRequest),
            addToPostTags: JSON.stringify(addToPostTags),
            removeFromPostTags: JSON.stringify(removeFromPostTags)
        };
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/add-remove-post-tags-from-posts-by-filter');
        if (response) {
            yield put(addRemovePostTagsFromPostByFilterSuccess(addToPostTags, removeFromPostTags));
            yield put(modalHideAddOrRemovePostTagsFromPosts());
            yield put(showNotification('Tags have been updated successfully.'));
            yield put(searchPostsRequestAction(searchRequestInput));
            yield put(cleanupAddRemovePostTagsFromPostsState());
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(addRemovePostTagsFromPostByFilterError(new SubmissionError({ _error: applicationError })));
    }
}

function* changePostSortDir(action) {
    const { payload } = action;
    const { sortDir } = payload;
    const selectQueryFromFilterSelectorByContext = makeSelectQueryFromFilterSelectorByContext();
    const globalQueryParams = yield select(selectQueryFromFilterSelectorByContext, POST_TAGGING);
    const to = {
        pathname: postsRoute,
        query: Object.assign(globalQueryParams, { sortDir })
    };
    yield put(push(to));
}

function* getPostRequest(action) {
    const { payload } = action;
    const { postId } = payload;

    let requestUrl = '/client-index/load-post-details';
    const parameters = { postId };

    const isSharedDashboardContext = yield select(selectIsSharedDashboardContext);
    if (isSharedDashboardContext) {
        requestUrl = '/client-index/load-shared-dashboard-post-details';
    }

    try {
        const serverRequest = createServerRequest(parameters);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, requestUrl);
        if (response) {
            const { post } = response.jsonData;
            const postPostTags = parsePostPostTags(post.postPostTags);
            const searchRequestInput = yield select(getPostsSearchInput);
            const postTextKeywords = getPostText(searchRequestInput);
            yield put(getPostSuccess(postId, parsePost(post, postTextKeywords), postPostTags));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(getPostError(postId, applicationError));
    }
}

function* sharedDashboardPostGetRequest(action) {
    const { payload } = action;
    const { postId, metricRequest } = payload;
    const parameters = {
        postId,
        metricRequest: JSON.stringify(getMetricRequest(metricRequest))
    };

    try {
        const serverRequest = createServerRequest(parameters);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/load-shared-dashboard-post-details');
        if (response) {
            const { post } = response.jsonData;
            const postPostTags = parsePostPostTags(post.postPostTags);
            const searchRequestInput = yield select(getPostsSearchInput);
            const postTextKeywords = getPostText(searchRequestInput);
            yield put(sharedDashboardPostGetSuccess(postId, parsePost(post, postTextKeywords), postPostTags));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(sharedDashboardPostGetError(postId, applicationError));
    }
}

export default function* postsSagas() {
    yield takeEvery(SEARCH_POSTS_REQUEST, searchPostsRequest);
    yield takeEvery(GET_POST_REQUEST, getPostRequest);
    yield takeEvery(SHARED_DASHBOARD_POST_GET_REQUEST, sharedDashboardPostGetRequest);
    yield takeEvery(PAGINATE_POSTS_REQUEST, paginatePostsRequest);
    yield takeEvery(ADD_REMOVE_POST_TAGS_FROM_POST_REQUEST, addRemovePostTagsFromPostRequest);
    yield takeEvery(ADD_REMOVE_POST_TAGS_FROM_POSTS_REQUEST, addRemovePostTagsFromPostsRequest);
    yield takeEvery(ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_REQUEST, addRemovePostTagsFromPostsByFilterRequest);
    yield takeEvery(CHANGE_POST_SORT_DIR, changePostSortDir);
}
