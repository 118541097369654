import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/headers/header.scss';

const PageHeadline = ({ children, className, small }) => (
    <DivWithTooltip tooltip={children} className={classnames(styles.headline, className, { [styles.big]: !small, [styles.small]: small })}>
        {children}
    </DivWithTooltip>
);

PageHeadline.propTypes = {
    children: customPropTypes.children.isRequired,
    className: PropTypes.string,
    small: PropTypes.bool // small is used in fixed headers (sticky at the top when scrolling)
};

PageHeadline.defaultProps = {
    small: false
};

export default PageHeadline;
