import ErrorBoundaryContent from 'src/components/errors/ErrorBoundaryContent';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/content.scss';
import ContentWidthContext from 'src/components/context/ContentWidthContext';
import withFlexDimensionProvider from './withFlexDimensionProvider';

const Content = (props) => {
    const { content, width } = props;
    return (
        <ContentWidthContext.Provider value={width}>
            <ErrorBoundaryContent content={content} />
        </ContentWidthContext.Provider>
    );
};

Content.propTypes = {
    content: PropTypes.node.isRequired,
    width: PropTypes.number.isRequired
};

const ContentWithFlexDimensionProvider = withFlexDimensionProvider(Content, true, false, 400);

//  https://github.com/ctrlplusb/react-sizeme#on-the-first-render-of-your-component
// eslint-disable-next-line react/jsx-props-no-spreading
const WrappedContentWithFlexDimensionProvider = (props) => <ContentWithFlexDimensionProvider className={styles.content} {...props} />;

const wrappedComponentName = ContentWithFlexDimensionProvider.displayName || ContentWithFlexDimensionProvider.name || 'Component';
WrappedContentWithFlexDimensionProvider.displayName = `wrappedContentWithFlexDimensionProvider(${wrappedComponentName})`;

export default WrappedContentWithFlexDimensionProvider;
