import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import _now from 'lodash/now';
import { Component } from 'react';
import { connect } from 'react-redux';
import { makeSelectServerDataForJobHash } from 'src/selectors/serverData';
import PropTypes from 'prop-types';
import { serverDataRequest } from 'src/actions/serverData';

class ServerDataSingleRequestDispatcher extends Component {
    componentDidMount() {
        const {
            serverData, serverDataRequestAction, serverRequest, ignoreCache, loadingIdentifier
        } = this.props;
        if (ignoreCache || _get(serverData, 'requested') === false || _get(serverData, 'error') !== null) {
            serverDataRequestAction(loadingIdentifier, [serverRequest.request], serverRequest.endpointInfo);
        }
    }

    componentDidUpdate(prevProps) {
        const { serverRequest, serverData } = this.props;
        if (prevProps.serverRequest !== serverRequest || _get(serverData, 'requested') === false) {
            const { ignoreCache, loadingIdentifier, serverDataRequestAction } = this.props;
            if (ignoreCache || _get(serverData, 'requested') === false || _get(serverData, 'error') !== null) {
                serverDataRequestAction(loadingIdentifier, [serverRequest.request], serverRequest.endpointInfo);
            }
        }
    }

    render() {
        const { children } = this.props;
        return children;
    }
}

ServerDataSingleRequestDispatcher.propTypes = {
    serverDataRequestAction: PropTypes.func.isRequired,
    serverRequest: customPropTypes.serverRequest.isRequired,
    serverData: PropTypes.object.isRequired,
    ignoreCache: PropTypes.bool,
    children: customPropTypes.children.isRequired,
    loadingIdentifier: PropTypes.string.isRequired,
};

ServerDataSingleRequestDispatcher.defaultProps = {
    ignoreCache: false,
};

const makeMapStateToProps = () => {
    const selectServerDataForJobHash = makeSelectServerDataForJobHash(_now());
    return (state, ownProps) => ({
        serverData: selectServerDataForJobHash(state, ownProps.serverRequest.request.hash)
    });
};

export default connect(makeMapStateToProps, { serverDataRequestAction: serverDataRequest })(ServerDataSingleRequestDispatcher);
