import React, { PureComponent } from 'react';
import ReactGrid from 'react-grid-layout';
import 'src/stylesheets/reactGridLayout.scss';

class Grid extends PureComponent {
    render() {
        return <ReactGrid.Responsive {...this.props} />;
    }
}

export default Grid;
