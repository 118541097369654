import withServerData from 'src/components/withServerData';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

const MetricWarningProvider = ({ children, serverData }) => {
    const warnings = _get(serverData, 'data.warnings');
    return children(warnings);
};

MetricWarningProvider.propTypes = {
    children: PropTypes.func.isRequired,
    serverData: PropTypes.shape({ data: PropTypes.shape({ warnings: PropTypes.object }) }).isRequired,
};

const MetricWarningProviderWithServerData = withServerData(MetricWarningProvider);

MetricWarningProviderWithServerData.propTypes = {
    requestHash: PropTypes.string.isRequired
};

export default MetricWarningProviderWithServerData;
