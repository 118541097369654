import { makeSelectServerDataForJobHash, selectTimestampByHash } from 'src/selectors/serverData';
import * as serverDataActions from 'src/actions/serverData';
import _now from 'lodash/now';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const MetricRefreshButtonProvider = (props) => {
    const {
        loading, serverDataClearCacheForHashAction, lastUpdatedTime, metricRequestIdentifier, renderButton
    } = props;

    const onClick = () => { serverDataClearCacheForHashAction([metricRequestIdentifier]); };
    return renderButton(onClick, loading, lastUpdatedTime);
};

MetricRefreshButtonProvider.propTypes = {
    serverDataClearCacheForHashAction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    lastUpdatedTime: PropTypes.number,
    small: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    serverDataHash: PropTypes.string.isRequired,
    metricRequestIdentifier: PropTypes.string.isRequired,
    renderButton: PropTypes.func.isRequired
};

MetricRefreshButtonProvider.defaultProps = {
    small: false,
    className: '',
    label: ''
};

const makeMapStateToProps = () => {
    const selectServerDataForJobHash = makeSelectServerDataForJobHash(_now());
    return (state, ownProps) => {
        const { serverDataHash } = ownProps;
        const serverData = selectServerDataForJobHash(state, serverDataHash);
        const lastUpdatedTime = selectTimestampByHash(state, serverDataHash);
        const loading = serverData.loading || false;
        return {
            loading,
            lastUpdatedTime
        };
    };
};

export default connect(makeMapStateToProps, {
    serverDataClearCacheForHashAction: serverDataActions.serverDataClearForRequestIdentifier
})(MetricRefreshButtonProvider);
