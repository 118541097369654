import { adAccountForceDeleteSuccess, adAccountDeleteSuccess, adAccountCustomizeSuccess } from 'src/actions/adAccounts';
import {
    BULK_PROFILE_ADD_SUCCESS,
    PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_SUCCESS,
    PROFILE_CUSTOMIZE_SUCCESS,
    PROFILE_DELETE_SUCCESS,
    PROFILE_FORCE_DELETE_SUCCESS
} from 'src/actions/profiles';
import { invalidatePostSearchHash } from 'src/actions/posts';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import { CUSTOM_METRIC_SAVE_AS_SUCCESS } from 'src/actions/customMetric';
import { PROFILE_USE_CASES_SET_SUCCESS } from 'src/actions/profileUseCases';
import { ACCOUNT_ENTITIES_GET_SUCCESS } from 'src/actions/accounts';
import { serverDataInvalidateHashKeys } from 'src/actions/serverData';
import { POST_TAG_RULE_DELETE_SUCCESS, POST_TAG_RULE_ADD_SUCCESS, POST_TAG_RULE_UPDATE_SUCCESS } from 'src/actions/postTagRule';
import {
    POST_TAG_EDIT_SUCCESS, POST_TAG_AUTOMATION_ACTIVATE_SUCCESS, POST_TAG_FORCE_REMOVE_SUCCESS, POST_TAGS_REMOVE_SUCCESS
} from 'src/actions/postTags';
import { ACCOUNT_OPTIONS_SAVE_SUCCESS } from 'src/actions/loggedInUser';
import { adAccountUseCaseSetSuccess } from 'src/actions/adAccountUseCases';

const pathToDataOnReducer = 'serverData.data';

const dispatchNonEmptyKeys = (store, keysToInvalidate) => {
    if (keysToInvalidate.length > 0) {
        store.dispatch(serverDataInvalidateHashKeys(keysToInvalidate));
    }
};

const handleFullCacheCleanup = (store) => {
    const state = store.getState();
    const serverDataState = _get(state, pathToDataOnReducer, {});
    const allCacheKeys = Object.keys(serverDataState, {});
    dispatchNonEmptyKeys(store, allCacheKeys);
};

const handlePostSearchHashCleanup = (store) => {
    store.dispatch(invalidatePostSearchHash());
};

const serverDataCacheInvalidatorEvents = [
    PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_SUCCESS,
    PROFILE_CUSTOMIZE_SUCCESS,
    PROFILE_DELETE_SUCCESS,
    PROFILE_FORCE_DELETE_SUCCESS,
    BULK_PROFILE_ADD_SUCCESS,
    CUSTOM_METRIC_SAVE_AS_SUCCESS,
    PROFILE_USE_CASES_SET_SUCCESS,
    ACCOUNT_ENTITIES_GET_SUCCESS,
    POST_TAG_FORCE_REMOVE_SUCCESS,
    POST_TAGS_REMOVE_SUCCESS,
    POST_TAG_EDIT_SUCCESS,
    ACCOUNT_OPTIONS_SAVE_SUCCESS,
    adAccountDeleteSuccess.type,
    adAccountForceDeleteSuccess.type,
    adAccountCustomizeSuccess.type,
    adAccountUseCaseSetSuccess.type
];

const postSearchHashInvalidatorEvents = [
    PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_SUCCESS,
    PROFILE_CUSTOMIZE_SUCCESS,
    PROFILE_DELETE_SUCCESS,
    PROFILE_FORCE_DELETE_SUCCESS,
    BULK_PROFILE_ADD_SUCCESS,
    PROFILE_USE_CASES_SET_SUCCESS,
    ACCOUNT_ENTITIES_GET_SUCCESS,
    POST_TAG_RULE_DELETE_SUCCESS,
    POST_TAG_RULE_ADD_SUCCESS,
    POST_TAG_RULE_UPDATE_SUCCESS,
    POST_TAG_AUTOMATION_ACTIVATE_SUCCESS,
    POST_TAG_FORCE_REMOVE_SUCCESS,
    POST_TAGS_REMOVE_SUCCESS,
    POST_TAG_EDIT_SUCCESS,
    ACCOUNT_OPTIONS_SAVE_SUCCESS,
    adAccountDeleteSuccess.type,
    adAccountForceDeleteSuccess.type,
    adAccountCustomizeSuccess.type,
    adAccountUseCaseSetSuccess.type
];

const serverDataCacheInvalidator = (store) => (next) => (action) => {
    const { type } = action;
    if (_includes(serverDataCacheInvalidatorEvents, type)) {
        handleFullCacheCleanup(store);
    }

    if (_includes(postSearchHashInvalidatorEvents, type)) {
        handlePostSearchHashCleanup(store);
    }
    return next(action);
};

export default serverDataCacheInvalidator;
