import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/headers/header.scss';

const Header = (props) => {
    const {
        children, className, fixed, alignBottom, withSidebarOpen
    } = props;
    return (
        <div
          className={classnames(
              styles.header,
              className,
              { [styles.fixed]: fixed },
              { [styles.alignBottom]: alignBottom },
              { [styles.withSidebarOpen]: withSidebarOpen }
          )}
        >
            {children}
        </div>
    );
};

Header.propTypes = {
    children: customPropTypes.children,
    className: PropTypes.string,
    fixed: PropTypes.bool,
    alignBottom: PropTypes.bool,
    withSidebarOpen: PropTypes.bool
};

Header.defaultProps = {
    className: '',
    fixed: false,
    alignBottom: false,
    withSidebarOpen: false
};

export default Header;
