import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/feedback.scss';
import WarningContent from 'src/components/feedback/warnings/WarningContent';

const FacebookInsightsFacebookBug = (props) => {
    const { warningData } = props;
    const content = (
        <ul className={styles.list}>
            {
                warningData.map((warning) => (
                    <li key={warning.name} className={styles.listItem}>
                        {warning.name}
                        <ul className={styles.list}>
                            {
                                warning.profiles.map((profile) => (
                                    <li key={profile.id} className="profile-with-issue">
                                        {profile.name}
                                    </li>
                                ))
                            }
                        </ul>
                    </li>
                ))
            }
        </ul>
    );

    return (
        <WarningContent
          headline="Preliminary Facebook data"
          message="Facebook Fan and Follower data is known to be preliminary for the last day and numbers are typically too big."
          content={content}
        />
    );
};

FacebookInsightsFacebookBug.propTypes = {
    warningData: PropTypes.arrayOf(customPropTypes.facebookInsightsFacebookBugWarning).isRequired
};

export default FacebookInsightsFacebookBug;
