import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { reportError } from 'src/utils/reportError';

class ErrorBoundarySuspense extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, sentryEventId: null };
    }

    componentDidCatch(error) {
        // You can also log the error to an error reporting service
        const sentryEventId = reportError(error);
        this.setState({ hasError: true, sentryEventId });
    }

    render() {
        const { hasError, sentryEventId } = this.state;
        const { children, renderErrorBody } = this.props;

        if (hasError) {
            const error = new Error('Loading of required components has failed. Please reload the app in your browser. Please contact support@facelift-bbt.com in case the problem persists.');
            return renderErrorBody(error, sentryEventId);
        }

        return children;
    }
}

ErrorBoundarySuspense.propTypes = {
    children: customPropTypes.children.isRequired,
    renderErrorBody: PropTypes.func.isRequired
};

export default ErrorBoundarySuspense;
