import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/notesBox.scss';
import MetricPersonalNotes from 'src/components/MetricPersonalNotes';
import { connect } from 'react-redux';
import { modalShowDashboardMetricNoteModal } from 'src/actions/overlays';
import { getNotesEditToolTip } from 'src/components/metricTiles/layout/utils';
import withToolTip from 'src/components/withToolTip';

const Container = withToolTip((props) => <div {...props} />);

const NotesBox = ({
    notes, dashboardMetricId, onNotesClickAction, readOnly
}) => (
    <Container
      className={styles.container}
      tooltip={getNotesEditToolTip(notes, readOnly)}
      referenceWrapperClassName={styles.wrapper}
      onClick={() => {
          onNotesClickAction(dashboardMetricId, !notes, readOnly);
      }}
    >
        {
            notes && (
            <div
              className={styles.text}
            ><MetricPersonalNotes notes={notes} />
            </div>
            )
        }
        {
            !readOnly && !notes && (
                <span>+ Notes</span>
            )
        }
    </Container>
);

NotesBox.propTypes = {
    notes: PropTypes.string,
    dashboardMetricId: PropTypes.string.isRequired,
    onNotesClickAction: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
};

NotesBox.defaultProps = {
    notes: '',
    readOnly: false
};

export default connect(null, {
    onNotesClickAction: modalShowDashboardMetricNoteModal
})(NotesBox);
