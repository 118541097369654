import {
    TABLE_PAGINATION_INITIALIZE,
    TABLE_PAGINATION_RESET,
    TABLE_PAGINATION_SET_PAGE,
    TABLE_PAGINATION_SET_SORT_BY
} from 'src/actions/tablePagination';

import _omit from 'lodash/omit';
import { setIn } from 'src/reducers/utils';

export function tablePagination(state = {}, action) {
    switch (action.type) {
        case TABLE_PAGINATION_INITIALIZE: {
            const {
                identifier, page, sortBy, sortDir, paginate
            } = action.payload;
            return Object.assign({}, state, {
                [identifier]: {
                    page, sortBy, sortDir, paginate
                }
            });
        }

        case TABLE_PAGINATION_RESET: {
            const { identifier } = action.payload;
            return _omit(state, identifier);
        }

        case TABLE_PAGINATION_SET_SORT_BY: {
            const { identifier, sortDir, sortBy } = action.payload;
            const page = 0;
            const newState = Object.assign({}, state[identifier], { page, sortBy, sortDir });
            return Object.assign({}, state, { [identifier]: newState });
        }

        case TABLE_PAGINATION_SET_PAGE: {
            const { identifier, page } = action.payload;
            return setIn(state, `${identifier}.page`, page);
        }
        default:
            return state;
    }
}
