import React, { Component } from 'react';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import BatchMetricRequestContext from './BatchMetricRequestContext';
import MetricRequestContext from './MetricRequestContext';

const createSingleRequestFromBatchRequest = (request, endpointInfo) => ({
    request,
    endpointInfo
});

class BatchToSingleMetricRequestTranslator extends Component {
    constructor(props) {
        super(props);
        this.memoizedCreateSingleRequestFromBatchRequest = memoizeOne(createSingleRequestFromBatchRequest);
    }

    render() {
        const { dashboardMetricId, children } = this.props;
        return (
            <BatchMetricRequestContext.Consumer>
                {
                    (batchServerRequest) => {
                        const requestIndex = batchServerRequest.entityIdToRequestMapping[dashboardMetricId];
                        const request = batchServerRequest.requests[requestIndex];
                        const singleServerRequest = this.memoizedCreateSingleRequestFromBatchRequest(request, batchServerRequest.endpointInfo);
                        return (
                            <MetricRequestContext.Provider value={singleServerRequest}>
                                {
                                    children(singleServerRequest)
                                }
                            </MetricRequestContext.Provider>
                        );
                    }
                }
            </BatchMetricRequestContext.Consumer>
        );
    }
}

BatchToSingleMetricRequestTranslator.propTypes = {
    children: PropTypes.func.isRequired,
    dashboardMetricId: PropTypes.string.isRequired
};

export default BatchToSingleMetricRequestTranslator;
