import * as customPropTypes from 'src/customPropTypes';
import * as serverDataActions from 'src/actions/serverData';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { selectIsBatchLoading } from 'src/selectors/serverData';
import withTooltip from 'src/components/withToolTip';

const ButtonWithTooltip = withTooltip(Button);

const DashboardRefreshButton = (props) => {
    const {
        serverDataClearForRequestIdentifierAction, loading, batchServerRequest, label
    } = props;
    return (
        <ButtonWithTooltip
          label={label}
          tooltip="Refresh all metrics"
          icon="refresh"
          disabled={loading}
          onClick={() => { serverDataClearForRequestIdentifierAction(batchServerRequest.requests.map((request) => request.identifier)); }}
        />
    );
};

DashboardRefreshButton.propTypes = {
    serverDataClearForRequestIdentifierAction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    batchServerRequest: customPropTypes.batchServerRequest.isRequired,
    label: PropTypes.string
};

DashboardRefreshButton.defaultProps = {
    label: ''
};

const makeMapStateToProps = () => (state, ownProps) => ({
    loading: selectIsBatchLoading(state, ownProps.batchServerRequest.batchIdentifier)
});

export default connect(makeMapStateToProps, {
    serverDataClearForRequestIdentifierAction: serverDataActions.serverDataClearForRequestIdentifier
})(DashboardRefreshButton);
