import {
    SERVER_DATA_BUCKET_ERROR,
    SERVER_DATA_BUCKET_SUCCESS,
    SERVER_DATA_CLEAR_CACHE,
    SERVER_DATA_INVALIDATE_HASH_KEYS,
    SERVER_DATA_REQUEST,
    SERVER_DATA_SUCCESS,
    SERVER_DATA_CLEAR_ALL_CACHE
} from 'src/actions/serverData';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import { combineReducers } from 'redux';

const data = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case SERVER_DATA_REQUEST: {
            const { serverDataJobs } = payload;
            const newObjects = {};
            serverDataJobs.forEach((serverDataJob) => {
                Object.assign(newObjects, {
                    [serverDataJob.hash]: {
                        data: {
                            error: null, data: null, loading: true
                        },
                        timestamp: null,
                        params: serverDataJob.params,
                        requestIdentifier: serverDataJob.identifier,
                        dependency: serverDataJob.dependency
                    }
                });
            });
            return Object.assign({}, state, newObjects);
        }
        case SERVER_DATA_BUCKET_ERROR:
        case SERVER_DATA_BUCKET_SUCCESS: {
            const { collectedResponses } = payload;
            const newResponses = {};
            collectedResponses.forEach((response) => {
                const { hash, data: dataResult, timestamp } = response;
                const newResponse = Object.assign({}, state[hash], { timestamp, data: dataResult });
                Object.assign(newResponses, Object.assign({ [hash]: newResponse }));
            });
            if (Object.keys(newResponses).length > 0) {
                return Object.assign({}, state, newResponses);
            }

            return state;
        }

        case SERVER_DATA_INVALIDATE_HASH_KEYS: {
            const { keysToInvalidate } = action.payload;
            if (!_isEmpty(keysToInvalidate)) {
                return _omit(state, keysToInvalidate);
            }
            return state;
        }

        case SERVER_DATA_CLEAR_CACHE: {
            const { requestIdentifiers } = payload;
            const hashesToOmit = [];
            Object.keys(state).forEach((hash) => {
                const currentObject = state[hash];
                if (_includes(requestIdentifiers, currentObject.requestIdentifier)) {
                    hashesToOmit.push(hash);
                }
            });
            if (hashesToOmit.length > 0) {
                return _omit(state, hashesToOmit);
            }
            return state;
        }
        case SERVER_DATA_CLEAR_ALL_CACHE: {
            if (!_isEmpty(state)) {
                return {};
            }
            return state;
        }
        default:
            return state;
    }
};

const batchLoadingStates = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case SERVER_DATA_REQUEST: {
            const { batchIdentifier } = payload;
            const countPerBatchIdentifier = _get(state, batchIdentifier, 0);
            const newLoadingState = countPerBatchIdentifier + 1;
            return Object.assign({}, state, { [batchIdentifier]: newLoadingState });
        }
        case SERVER_DATA_SUCCESS: {
            const { batchIdentifier } = payload;
            const countPerBatchIdentier = _get(state, batchIdentifier);
            if (countPerBatchIdentier) {
                return Object.assign({}, state, { [batchIdentifier]: (countPerBatchIdentier - 1) });
            }
            return state;
        }
        default:
            return state;
    }
};

export const serverData = combineReducers({
    data,
    batchLoadingStates
});
