import React from 'react';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/dataWarning.scss';

const WarningContent = ({
    message, content, headline, buttons
}) => (
    <div className={styles.wrapper}>
        <div className={styles.headline}>{headline}</div>
        <div className={styles.message}>{message}</div>
        { content && <div className={styles.content}>{content}</div>}
        { buttons && <div className={styles.buttons}>{buttons}</div>}
    </div>
);

WarningContent.propTypes = {
    headline: PropTypes.node.isRequired,
    message: PropTypes.node.isRequired,
    content: PropTypes.node,
    buttons: PropTypes.node
};

export default WarningContent;
