import { makeSelectServerDataForJobHash, selectTimestampByHash } from 'src/selectors/serverData';
import _now from 'lodash/now';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

const withServerData = (WrappedComponent) => {
    const WithServerData = (props) => <WrappedComponent {...props} />;

    WithServerData.propTypes = {
        serverData: PropTypes.object.isRequired,
        requestHash: PropTypes.string.isRequired,
        lastUpdatedTime: PropTypes.number
    };

    const makeMapStateToProps = () => {
        const selectServerDataForJobHash = makeSelectServerDataForJobHash(_now());
        return (state, ownProps) => {
            const {
                requestHash
            } = ownProps;
            const serverData = selectServerDataForJobHash(state, requestHash);
            const lastUpdatedTime = selectTimestampByHash(state, requestHash);
            return {
                serverData,
                lastUpdatedTime
            };
        };
    };
    return connect(makeMapStateToProps)(WithServerData);
};

export default withServerData;
