import _has from 'lodash/has';

export const getFiltersWithCustomBindingFromDashboardMetricSettings = (dashboardMetricSettings) => {
    const filtersWithCustomBinding = [];
    if (dashboardMetricSettings.isCustomProfileSelected) {
        filtersWithCustomBinding.push('profile');
    }
    if (dashboardMetricSettings.isCustomDateSelected) {
        filtersWithCustomBinding.push('date');
    }
    if (dashboardMetricSettings.isCustomAdditionalFilterSelected) {
        if (_has(dashboardMetricSettings.settingAdditionalFilterSelection, 'postText')) {
            filtersWithCustomBinding.push('postText');
        }
        if (_has(dashboardMetricSettings.settingAdditionalFilterSelection, 'postTag')) {
            filtersWithCustomBinding.push('postTag');
        }
        if (_has(dashboardMetricSettings.settingAdditionalFilterSelection, 'adCampaign')) {
            filtersWithCustomBinding.push('adCampaign');
        }
    }

    return filtersWithCustomBinding;
};

export const getNotesEditToolTip = (notes, readOnly = false) => {
    if (readOnly) {
        return 'View notes';
    }
    if (!notes) {
        return 'Add notes';
    }

    return 'View or edit notes';
};
