import Button from 'src/components/buttons/Button';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import WarningContent from 'src/components/feedback/warnings/WarningContent';
import { getProfileNamesFromUseCaseViolationWarnings } from 'src/utils/postSearch';
import memoizeOne from 'memoize-one';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

const memoizeOneGetProfileNamesFromUseCaseViolationWarnings = memoizeOne(getProfileNamesFromUseCaseViolationWarnings);

const UseCaseViolation = ({ warningData, pushAction }) => {
    const profileNames = memoizeOneGetProfileNamesFromUseCaseViolationWarnings(warningData);
    const message = `The following profile${profileNames.length > 1 ? 's' : ''} could not load data because the private stats authentication has become invalid. Go to the 'Authentications' section and authenticate with a valid user.`;
    const detail = (
        <ul>
            {
                profileNames.map((profileName) => (
                    <li key={profileName}>{profileName}</li>
                ))
            }
        </ul>
    );
    return (
        <WarningContent
          headline="Invalid private stats authentication"
          content={detail}
          message={message}
          buttons={<Button onClick={() => { pushAction('/authentications'); }} feedbackWarning label="Go to Authentications" />}
        />
    );
};

UseCaseViolation.propTypes = {
    pushAction: PropTypes.func.isRequired,
    warningData: PropTypes.arrayOf(customPropTypes.useCaseViolationWarning).isRequired
};

export default connect(null, { pushAction: push })(UseCaseViolation);
