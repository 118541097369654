import ErrorView from 'src/components/errors/ErrorView';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { reportError } from 'src/utils/reportError';

const getContentPathname = (content) => _get(content, ['props', 'location', 'pathname'], '');

class ErrorBoundaryContent extends React.Component {
    constructor(props) {
        super(props);
        const { content } = props;
        this.state = { hasError: false, pathname: getContentPathname(content), sentryEventId: null };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { content } = nextProps;
        const pathname = getContentPathname(content);
        const stateObject = { pathname };
        if (prevState.hasError && prevState.pathname !== pathname) {
            stateObject.hasError = false;
            stateObject.sentryEventId = null;
        }
        return stateObject;
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error) {
        const sentryEventId = reportError(error);
        this.setState({ sentryEventId });
    }

    render() {
        const { hasError, sentryEventId } = this.state;
        const { content } = this.props;

        if (hasError) {
            const error = { message: 'If you continue to see this message, please reload the tool or contact our support.' };
            return (
                <ErrorView
                  headline="Something went wrong!"
                  error={error}
                  sentryEventId={sentryEventId}
                />
            );
        }
        return content;
    }
}

ErrorBoundaryContent.propTypes = {
    content: PropTypes.node.isRequired
};

export default ErrorBoundaryContent;
