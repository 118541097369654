import * as customPropTypes from 'src/customPropTypes';
import { createSingleServerRequestForDashboardMetric } from 'src/requestHandling/metricDataRequests';
import memoizeOne from 'memoize-one';
import MetricRequestContext from 'src/components/context/MetricRequestContext';
import PropTypes from 'prop-types';
import React from 'react';

const defaultPaginationAndSorting = {};

const memoizedCreateSingleServerRequestForDashboardMetric = memoizeOne(createSingleServerRequestForDashboardMetric);

const DashboardMetricRequestProvider = (props) => {
    const {
        children, dashboardMetric, selectedProfilesOrGroups, selectedDate, format, selectedPostText, selectedPostTextExclude, selectedPostTag, isSharedDashboardContext, selectedAdCampaign
    } = props;

    const identifier = `dashboard-metric-${dashboardMetric.id}`;
    const serverRequest = memoizedCreateSingleServerRequestForDashboardMetric(
        identifier,
        selectedProfilesOrGroups,
        selectedDate,
        selectedPostText,
        selectedPostTextExclude,
        selectedPostTag,
        selectedAdCampaign,
        dashboardMetric,
        format,
        defaultPaginationAndSorting,
        isSharedDashboardContext
    );
    return (
        <MetricRequestContext.Provider
          value={serverRequest}
        >
            { children(serverRequest) }
        </MetricRequestContext.Provider>
    );
};

DashboardMetricRequestProvider.propTypes = {
    children: PropTypes.func.isRequired,
    dashboardMetric: customPropTypes.dashboardMetric.isRequired,
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    selectedDate: customPropTypes.selectedDate.isRequired,
    format: customPropTypes.format.isRequired,
    selectedPostText: customPropTypes.postTextFilter.isRequired,
    selectedPostTextExclude: customPropTypes.postTextFilter.isRequired,
    selectedPostTag: customPropTypes.postTagFilter.isRequired,
    selectedAdCampaign: customPropTypes.adCampaignFilter.isRequired,
    isSharedDashboardContext: PropTypes.bool
};

DashboardMetricRequestProvider.defaultProps = {
    isSharedDashboardContext: false
};

export default DashboardMetricRequestProvider;
