import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/dataSourceLabel.scss';
import { Link } from 'react-router';
import { getDataSourcePath } from 'src/utils/dataSources';
import IcomoonIcon from 'src/components/IcomoonIcon';

const DataSourceLabel = ({ name }) => (
    <Link to={getDataSourcePath(name)} className={styles.wrapper}>
        <IcomoonIcon icon="vist-page" className={styles.icon} />
        <div className={styles.name}>{name}</div>
    </Link>
);

DataSourceLabel.propTypes = {
    name: PropTypes.string.isRequired
};

export default DataSourceLabel;
