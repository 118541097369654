import _get from 'lodash/get';
import { createSelector } from 'reselect';

const getLastScrolledPositionFromStore = (state) => state.lastScrolledPosition;

export const makeSelectLastScrolledPositionByIdentifier = (scrollPositionName) => createSelector(
    [
        getLastScrolledPositionFromStore,
        (_, identifier) => identifier
    ],
    (lastScrolledPositions, identifier) => _get(lastScrolledPositions, [scrollPositionName, identifier], 0)
);
