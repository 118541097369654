import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/headers/header.scss';

const TopRow = ({ children, className }) => (
    <div className={classnames(styles.row, className)}>{children}</div>
);

TopRow.propTypes = {
    className: PropTypes.string,
    children: customPropTypes.children.isRequired,
};

TopRow.defaultProps = {
    className: ''
};

export default TopRow;
