import { combineReducers } from 'redux';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';

const tagsById = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN: {
            return payload.tags.tags;
        }
        default:
            return state;
    }
};

const tagIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN: {
            return [...Object.keys(payload.tags.tags)];
        }
        default:
            return state;
    }
};

const tagTypes = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN: {
            return payload.tags.tagTypes;
        }
        default:
            return state;
    }
};

export const tags = combineReducers({ tagsById, tagIds, tagTypes });
