import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import _now from 'lodash/now';
import { Component } from 'react';
import { connect } from 'react-redux';
import { makeSelectServerDataByServerDataParams } from 'src/selectors/serverData';
import PropTypes from 'prop-types';
import { serverDataRequest } from 'src/actions/serverData';

class ServerDataBatchRequestDispatcher extends Component {
    componentDidMount() {
        const {
            batchServerRequest,
            serverData
        } = this.props;
        this.runJobIfRequired(batchServerRequest, serverData, true);
    }

    componentDidUpdate() {
        const { batchServerRequest, serverData } = this.props;
        this.runJobIfRequired(batchServerRequest, serverData);
    }

    runJobIfRequired(batchServerRequest, serverData, retryFailedJobs = false) {
        const { serverDataRequestAction } = this.props;
        const filteredJobs = batchServerRequest.requests.filter((request) => {
            const jobHash = request.hash;
            if (_get(serverData, [jobHash, 'requested']) === false) {
                return true;
            }
            return retryFailedJobs && _get(serverData, [jobHash, 'error'], null) !== null;
        });

        if (filteredJobs.length > 0) {
            serverDataRequestAction(batchServerRequest.batchIdentifier, filteredJobs, batchServerRequest.endpointInfo, batchServerRequest.requestVariables);
        }
    }

    render() {
        const { children } = this.props;
        return children;
    }
}

ServerDataBatchRequestDispatcher.propTypes = {
    serverDataRequestAction: PropTypes.func.isRequired,
    batchServerRequest: customPropTypes.batchServerRequest.isRequired,
    serverData: PropTypes.object.isRequired,
    children: customPropTypes.children.isRequired
};

const makeMapStateToProps = () => {
    const selectServerDataByServerDataParams = makeSelectServerDataByServerDataParams(_now());
    return (state, ownProps) => {
        const { batchServerRequest } = ownProps;
        const serverData = selectServerDataByServerDataParams(state, batchServerRequest);
        return { serverData };
    };
};
export default connect(makeMapStateToProps, { serverDataRequestAction: serverDataRequest })(ServerDataBatchRequestDispatcher);
