import { combineReducers } from 'redux';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';
import {
    SORT_POST_TAG_RULES,
    POST_TAG_RULE_ADD_ERROR,
    POST_TAG_RULE_ADD_REQUEST,
    POST_TAG_RULE_ADD_SUCCESS,
    POST_TAG_RULE_UPDATE_REQUEST,
    POST_TAG_RULE_UPDATE_ERROR,
    POST_TAG_RULE_UPDATE_SUCCESS,
    POST_TAG_RULES_GET_SUCCESS,
    POST_TAG_RULE_DELETE_SUCCESS
} from 'src/actions/postTagRule';
import { POST_TAG_RULE_IDS_CLEAN_UP } from 'src/actions/postTags';
import _omit from 'lodash/omit';
import _difference from 'lodash/difference';

const byId = (state = {}, actions) => {
    const { type, payload } = actions;
    switch (type) {
        case USER_LOGGED_IN:
        case POST_TAG_RULES_GET_SUCCESS: {
            const { postTagRules } = payload;
            return postTagRules;
        }
        case POST_TAG_RULE_ADD_SUCCESS:
        case POST_TAG_RULE_UPDATE_SUCCESS: {
            const { postTagRule } = payload;
            if (postTagRule) {
                return Object.assign({}, state, { [postTagRule.id]: postTagRule });
            }
            return state;
        }
        case POST_TAG_RULE_DELETE_SUCCESS: {
            const { postTagRuleId } = payload;
            return _omit(state, [postTagRuleId]);
        }
        case POST_TAG_RULE_IDS_CLEAN_UP: {
            const { postTagRuleIds } = payload;
            return _omit(state, postTagRuleIds);
        }
        default: {
            return state;
        }
    }
};

const allIds = (state = [], actions) => {
    const { type, payload } = actions;
    switch (type) {
        case USER_LOGGED_IN:
        case POST_TAG_RULES_GET_SUCCESS: {
            const { postTagRules } = payload;
            return Object.keys(postTagRules);
        }
        case POST_TAG_RULE_DELETE_SUCCESS: {
            const { postTagRuleId } = payload;
            return _difference(state, [postTagRuleId]);
        }
        case POST_TAG_RULE_IDS_CLEAN_UP: {
            const { postTagRuleIds } = payload;
            return _difference(state, postTagRuleIds);
        }
        case POST_TAG_RULE_ADD_SUCCESS: {
            const { postTagRule } = payload;
            if (postTagRule) {
                return [...state, postTagRule.id];
            }
            return state;
        }
        default: {
            return state;
        }
    }
};

const ascendingSortDir = 'asc';
const descendingSortDir = 'desc';

const defaultState = {
    sortBy: 'name',
    sortDir: ascendingSortDir
};

const listTableConfig = (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SORT_POST_TAG_RULES: {
            const { sortBy } = payload;
            let newSortOrder = ascendingSortDir;
            if (state.sortBy === sortBy && state.sortDir === ascendingSortDir) {
                newSortOrder = descendingSortDir;
            }
            return Object.assign({}, state, { sortBy, sortDir: newSortOrder });
        }
        default:
            return state;
    }
};

const asyncStates = combineReducers({
    numberOfHistoricPostsGet: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'state',
        [POST_TAG_RULE_ADD_REQUEST, POST_TAG_RULE_UPDATE_REQUEST],
        [POST_TAG_RULE_ADD_SUCCESS, POST_TAG_RULE_UPDATE_SUCCESS],
        [POST_TAG_RULE_ADD_ERROR, POST_TAG_RULE_UPDATE_ERROR]
    )
});

export const postTagRules = combineReducers({
    byId,
    allIds,
    listTableConfig,
    asyncStates
});
