import Button from 'src/components/buttons/Button';
import Date from 'src/components/Date';
import PropTypes from 'prop-types';
import React from 'react';
import withTooltip from 'src/components/withToolTip';
import moment from 'moment';
import MetricRefreshButtonProvider from './MetricRefreshButtonProvider';

const ButtonWithTooltip = withTooltip(Button);

const MetricRefreshButton = (props) => {
    const {
        label, small, className, metricRequestIdentifier, serverDataHash
    } = props;

    return (
        <MetricRefreshButtonProvider
          serverDataHash={serverDataHash}
          metricRequestIdentifier={metricRequestIdentifier}
          renderButton={(onClick, loading, lastUpdatedTime) => {
              let tooltip = 'Refreshing data...';
              // last updateTime is in milliseconds from lodash _now.
              const time = moment.unix(lastUpdatedTime / 1000);
              if (!loading) {
                  tooltip = lastUpdatedTime ? <span>Last refreshed:<br /><Date format="datetime" date={time.utc().format('YYYY-MM-DD HH:mm:ss')} appendTimezone /></span> : null;
              }
              return (
                  <ButtonWithTooltip
                    label={label}
                    icon="refresh"
                    disabled={loading}
                    small={small}
                    tooltip={tooltip}
                    onClick={onClick}
                    className={className}
                  />
              );
          }}
        />
    );
};

MetricRefreshButton.propTypes = {
    small: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    serverDataHash: PropTypes.string.isRequired,
    metricRequestIdentifier: PropTypes.string.isRequired
};

MetricRefreshButton.defaultProps = {
    small: false,
    className: '',
    label: ''
};

export default MetricRefreshButton;
