import React, { Component } from 'react';
import FilterContext from 'src/components/context/FilterContext';
import PropTypes from 'prop-types';
import { setFilterSelection } from 'src/actions/filterSelector';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _isEqual from 'lodash/isEqual';

class FilterContextProvider extends Component {
    constructor(props) {
        super(props);
        const { setFilterSelectionAction, context, location } = this.props;
        setFilterSelectionAction(context, location.query);
    }

    componentDidUpdate(prevProps) {
        const { setFilterSelectionAction, context, location } = this.props;
        if (!_isEqual(prevProps.location, location)) {
            setFilterSelectionAction(context, location.query);
        }
    }

    render() {
        const { children, context } = this.props;
        return (
            <FilterContext.Provider value={context}>
                {children}
            </FilterContext.Provider>
        );
    }
}

FilterContextProvider.propTypes = {
    location: PropTypes.object,
    setFilterSelectionAction: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    context: PropTypes.string.isRequired
};

export default withRouter(connect(null, {
    setFilterSelectionAction: setFilterSelection
})(FilterContextProvider));
