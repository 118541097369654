import React from 'react';
import styles from 'src/stylesheets/dataSourceHints.scss';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const DataSourceHints = (props) => {
    const { hints, paddingSize } = props;
    return (
        <div className={styles.wrapper}>
            {
                hints.map((hint, i) => (
                    // hints does not specific key identifiers, indices used as keys
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={`hint_${i}`} className={classnames(styles.dataSourceHint, { [styles[paddingSize]]: true })}>
                        <div dangerouslySetInnerHTML={{ __html: hint }} />
                    </div>
                ))
            }
        </div>
    );
};

DataSourceHints.propTypes = {
    paddingSize: PropTypes.oneOf(['small', 'big']),
    hints: customPropTypes.dataSourceHints.isRequired
};

DataSourceHints.defaultProps = {
    paddingSize: 'small'
};

export default DataSourceHints;
