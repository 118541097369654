export const TABLE_PAGINATION_INITIALIZE = 'TABLE_PAGINATION_INITIALIZE';
export const TABLE_PAGINATION_RESET = 'TABLE_PAGINATION_RESET';
export const TABLE_PAGINATION_SET_SORT_BY = 'TABLE_PAGINATION_SET_SORT_BY';
export const TABLE_PAGINATION_SET_PAGE = 'TABLE_PAGINATION_SET_PAGE';

export const tablePaginationInitialize = (identifier, paginate, page, sortBy, sortDir) => ({
    type: TABLE_PAGINATION_INITIALIZE,
    payload: {
        identifier, paginate, page, sortBy, sortDir
    }
});

export const tablePaginationReset = (identifier) => ({
    type: TABLE_PAGINATION_RESET,
    payload: { identifier }
});

export const tablePaginationSetPage = (identifier, page) => ({
    type: TABLE_PAGINATION_SET_PAGE,
    payload: { identifier, page }
});

export const tablePaginationSetSortBy = (identifier, sortBy, sortDir) => ({
    type: TABLE_PAGINATION_SET_SORT_BY,
    payload: {
        identifier,
        sortBy,
        sortDir
    }
});
