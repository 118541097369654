import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/dashboardMetricScroller.scss';
import withTooltip from 'src/components/withToolTip';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

class DashboardMetricScroller extends Component {
    constructor(props) {
        super(props);
        this.keydown = this.keydown.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydown, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydown, false);
    }

    keydown(event) {
        const {
            nextLink,
            previousLink,
            routePushAction
        } = this.props;
        const left = 37;
        const right = 39;
        if (previousLink && event.keyCode === left) {
            routePushAction(previousLink);
        }
        if (nextLink && event.keyCode === right) {
            routePushAction(nextLink);
        }
    }

    render() {
        const {
            nextLink,
            previousLink,
            routePushAction
        } = this.props;

        return (
            <div className={styles.wrapper}>
                {
                    previousLink
                    && (
                        <IcomoonIconWithTooltip
                          tooltip={<>Previous metric [ &larr; left arrow]</>}
                          icon="chevron-big-left"
                          onClick={() => { routePushAction(previousLink); }}
                          className={styles.iconEnabled}
                          referenceWrapperClassName={styles.iconWrapper}
                        />
                    )
                }
                {
                    !previousLink && (
                        <IcomoonIconWithTooltip
                          icon="chevron-big-left"
                          className={styles.iconDisabled}
                          referenceWrapperClassName={styles.iconWrapper}
                          tooltip="No previous metric"
                        />
                    )
                }
                {
                    nextLink
                    && (
                        <IcomoonIconWithTooltip
                          tooltip={<>Next metric [ &rarr; right arrow]</>}
                          icon="chevron-big-right"
                          onClick={() => { routePushAction(nextLink); }}
                          className={styles.iconEnabled}
                          referenceWrapperClassName={styles.iconWrapper}
                        />
                    )
                }
                {
                    !nextLink && (
                        <IcomoonIconWithTooltip
                          icon="chevron-big-right"
                          className={styles.iconDisabled}
                          referenceWrapperClassName={styles.iconWrapper}
                          tooltip="No next metric"
                        />
                    )
                }
            </div>
        );
    }
}

DashboardMetricScroller.propTypes = {
    nextLink: customPropTypes.linkTo,
    previousLink: customPropTypes.linkTo,
    routePushAction: PropTypes.func.isRequired
};

export default DashboardMetricScroller;
