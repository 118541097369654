import * as customPropTypes from 'src/customPropTypes';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import { connect } from 'react-redux';
import React from 'react';
import { defaultPostTextState, defaultPostTagState, defaultPostTextExcludeState } from 'src/utils/filterSelectors';

const withAdditionalSettingsInterpretation = (WrappedChartView) => {
    const ChartViewWithAdditionalSettingsInterpretation = (props) => <WrappedChartView {...props} />;

    ChartViewWithAdditionalSettingsInterpretation.propTypes = {
        settings: customPropTypes.dashboardMetricSettings,
        selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
        selectedDate: customPropTypes.selectedDate.isRequired,
    };

    const wrappedChartViewName = WrappedChartView.displayName || WrappedChartView.name || 'Component';
    ChartViewWithAdditionalSettingsInterpretation.displayName = `withAdditionalSettingsInterpretation(${wrappedChartViewName})`;

    function mergeOwnProps(stateProps, dispatchProps, ownProps) {
        const { settings, selectedDate } = ownProps;

        const mergedProps = Object.assign({}, ownProps, stateProps, dispatchProps);
        if (_has(settings, 'isCustomProfileSelected') && settings.isCustomProfileSelected === true && settings.settingProfileSelection) {
            Object.assign(mergedProps, {
                selectedProfilesOrGroups: settings.settingProfileSelection
            });
        }

        if (_has(settings, 'isCustomDateSelected') && settings.isCustomDateSelected === true && !_isEmpty(settings.settingDateSelection)) {
            if (settings.inheritTimezone) {
                Object.assign(mergedProps, {
                    selectedDate: Object.assign({}, settings.settingDateSelection, { timezone: selectedDate.timezone })
                });
            } else {
                Object.assign(mergedProps, {
                    selectedDate: settings.settingDateSelection
                });
            }
        }
        if (_has(settings, 'isCustomAdditionalFilterSelected') && settings.isCustomAdditionalFilterSelected === true && _isObject(settings.settingAdditionalFilterSelection)) {
            const mergedAdditionalFilter = {
                postTag: defaultPostTagState,
                postText: defaultPostTextState,
                postTextExclude: defaultPostTextExcludeState
            };
            Object.assign(mergedAdditionalFilter, settings.settingAdditionalFilterSelection);

            Object.assign(mergedProps, {
                selectedPostText: mergedAdditionalFilter.postText,
                selectedPostTextExclude: mergedAdditionalFilter.postTextExclude,
                selectedPostTag: mergedAdditionalFilter.postTag
            });
        }
        return mergedProps;
    }

    const ConnectedChartViewWithAdditionalSettingsInterpretation = connect(null, null, mergeOwnProps)(ChartViewWithAdditionalSettingsInterpretation);
    ConnectedChartViewWithAdditionalSettingsInterpretation.propTypes = {
        settings: customPropTypes.dashboardMetricSettings
    };

    ConnectedChartViewWithAdditionalSettingsInterpretation.defaultProps = {
        settings: {}
    };
    return ConnectedChartViewWithAdditionalSettingsInterpretation;
};

export default withAdditionalSettingsInterpretation;
