import * as customPropTypes from 'src/customPropTypes';
import DataSourceLabel from 'src/components/DataSourceLabel';
import React from 'react';
import styles from 'src/stylesheets/views/metricDataSourceHints.scss';
import DataSourceHints from 'src/components/DataSourceHints';

const MetricDataSourcesHints = (props) => {
    const { dataSourceHints } = props;
    return (
        <div>
            {
                dataSourceHints.map((item) => {
                    const { id, dataSourceName, hints } = item;
                    return (
                        <div key={id} className={styles.dataSource}>
                            <div className={styles.label}>
                                <DataSourceLabel name={dataSourceName} />
                            </div>
                            <DataSourceHints hints={hints} />
                        </div>
                    );
                })
            }
        </div>
    );
};

MetricDataSourcesHints.propTypes = {
    dataSourceHints: customPropTypes.metricDataSourcesHints.isRequired
};

export default MetricDataSourcesHints;
