import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/metricTile.scss';

const MetricTile = ({ children }) => <div className={styles.tile}>{children}</div>;

MetricTile.propTypes = {
    children: customPropTypes.children.isRequired
};

export default MetricTile;
