import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/feedback.scss';
import WarningContent from 'src/components/feedback/warnings/WarningContent';

const DeprecatedDataSource = (props) => {
    const { warningData } = props;
    const warningMessage = warningData.length > 1
        ? 'The following data sources are deprecated and entail limited data. Therefore, their usage is highly discouraged:'
        : 'The following data source is deprecated and entails limited data. Therefore, its usage is highly discouraged:';

    const content = (
        <ul className={styles.list}>
            {
                warningData.map((warning) => (
                    <li key={warning.name} className={styles.listItem}>
                        {warning.name}
                    </li>
                ))
            }
        </ul>
    );

    return (
        <WarningContent
          headline="Deprecated data source"
          message={warningMessage}
          content={content}
        />
    );
};

DeprecatedDataSource.propTypes = {
    warningData: PropTypes.arrayOf(customPropTypes.deprecatedDataSourceWarning).isRequired
};

export default DeprecatedDataSource;
